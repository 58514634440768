<template>
  <div class="home">
    <h3 class="common-title">设置</h3>
    <div class="clearfix pb20">
    </div>
    <div class="home-left">
      <el-container>
        <el-main>
          <el-form
            ref="merchantRuleFormRef"
            :model="merchantForm"
            :rules="merchantRules"
            label-width="150px"
            height="calc(100vh - 300px)"
            class="home-form"
          >
            <el-form-item label="设施名称" prop="name">
              <el-input v-model="merchantForm.name"></el-input>
            </el-form-item>
            <div v-for="language in merchantServerLanguages">
              <el-form-item :label="language.name + '设施名称'" :prop="'multilingual_data.' + language.prefix + '_name'"
                            v-if="language.is_active">
                <el-input v-model="merchantForm.multilingual_data[language.prefix + '_name']"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="副标题" prop="subtitle">
              <el-input type="textarea" rows="3" v-model="merchantForm.subtitle"></el-input>
            </el-form-item>
            <div v-for="language in merchantServerLanguages">
              <el-form-item :label="language.name + '副标题'"
                            :prop="'multilingual_data.' + language.prefix + '_subtitle'"
                            v-if="language.is_active">
                <el-input type="textarea" rows="3"
                          v-model="merchantForm.multilingual_data[language.prefix + '_subtitle']"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="用户头像" prop="user_avatar">
              <el-upload
                class="avatar-uploader"
                accept="image/*"
                :auto-upload="false"
                :limit="1"
                :show-file-list="false"
                :on-change="userAvatarHandleChange"
              >
                <img v-if="merchantForm.user_avatar_url" :src="merchantForm.user_avatar_url" class="avatar"
                     alt="userAvatar" width="110" height="110"/>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>
            <el-form-item label="助手头像" prop="assistant_avatar">
              <el-upload
                class="avatar-uploader"
                accept="image/*"
                :auto-upload="false"
                :limit="1"
                :show-file-list="false"
                :on-change="assistantAvatarHandleChange"
              >
                <img v-if="merchantForm.assistant_avatar_url" :src="merchantForm.assistant_avatar_url" class="avatar"
                     alt="assistantAvatar" width="110" height="110"/>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus/>
                </el-icon>
              </el-upload>
            </el-form-item>

            <el-divider border-style="dashed"/>

            <el-form-item label="设施每日访问限制" prop="daily_limit">
              <el-input-number v-model="merchantForm.daily_limit" :min="1" :step="1"></el-input-number>
            </el-form-item>
            <el-form-item label="问题字数限制" prop="query_limit">
              <el-input-number v-model="merchantForm.query_limit" :min="1" :step="1"></el-input-number>
            </el-form-item>
            <el-form-item label="设施状态" prop="is_active">
              <el-switch v-model="merchantForm.is_active" active-text="启用" inactive-text="禁用"></el-switch>
            </el-form-item>
            <el-form-item label="流响应" prop="streaming">
              <el-switch v-model="merchantForm.streaming" active-text="启用" inactive-text="禁用"></el-switch>
            </el-form-item>

            <el-divider border-style="dashed"/>

            <el-form-item label="温度设置" prop="temperature">
              <el-input-number :min="0.1" :max="1.0" :step="0.1" :placeholder="config.openai_temperature"
                               v-model="merchantForm.temperature"></el-input-number>
            </el-form-item>

          </el-form>
        </el-main>
        <el-footer>
          <div class="home-left pb20">
            <div class="clearfix pb20">
              <div class="fr pr10">
                <el-button type="primary" @click="editMerchant">确定</el-button>
              </div>
            </div>
            <el-form>
              <el-divider style="color: red; margin-top: 40px;"></el-divider>
              <el-form-item label="删除设施" class="mt15" style="padding-left: 80px;">
                <template #label>
                  <span style="color: red;">删除设施</span>
                </template>
                <el-button type="danger" @click="deleteMerchant">删除</el-button>
              </el-form-item>

            </el-form>
          </div>
        </el-footer>
      </el-container>
    </div>

  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { authApi } from '@/api'
import { computed, onMounted } from "@vue/runtime-core";
import Empty from "@/components/Empty.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
// @ is an alias to /src

export default {
  name: 'Home',
  components: { Empty },
  setup() {

    const route = useRoute()
    const router = useRouter()

    // merchant
    const config = computed(() => {
      return store.getters.config
    })
    const merchant = computed(() => {
      return store.getters.merchant
    })
    const merchantServerLanguages = computed(() => {
      return merchant.value.server_languages.filter(item => item.is_active)
    })

    const merchantFormRaw = reactive({
      id: null,
      name: null,
      is_active: true,
      streaming: true,
      subtitle: null,
      user_avatar: null,
      user_avatar_url: null,
      assistant_avatar: null,
      assistant_avatar_url: null,
      daily_limit: 100,
      query_limit: 100,
      temperature: null,
      multilingual_data: {},
    })
    const merchantForm = reactive(JSON.parse(JSON.stringify(merchantFormRaw)))

    const merchantWelcomeContent = computed(() => {
      return `你好，我是${ merchantForm.name }的小助手`
    })

    const merchantRuleFormRef = ref(null)

    const merchantRulesRaw = reactive({
      name: [{ required: true, message: '请输入设施名称', trigger: 'blur' },],
      subtitle: [{ required: true, message: '请输入副标题', trigger: 'blur' },],
      daily_limit: [{ required: true, message: '请输入每日访问限制', trigger: 'blur' },],
      query_limit: [{ required: true, message: '请输入问题字数限制', trigger: 'blur' },],
    })
    const merchantRules = ref({})

    const mappingMerchant = () => {
      const newItem = merchant.value
      Object.keys(merchantFormRaw).forEach(key => {
        merchantForm[key] = newItem[key]
      })
      merchantForm.languages = newItem.server_languages.filter(item => item.is_active).map(item => item.id)
      if (newItem.user_avatar !== null) {
        merchantForm.user_avatar_url = `/api/admin/static/avatar/${ newItem.id }/${ newItem.user_avatar }`
      }
      if (newItem.assistant_avatar !== null) {
        merchantForm.assistant_avatar_url = `/api/admin/static/avatar/${ newItem.id }/${ newItem.assistant_avatar }`
      }
      let editMerchantRules = JSON.parse(JSON.stringify(merchantRulesRaw))
      for (let language in newItem.server_languages) {
        const prefix = newItem.server_languages[language].prefix
        editMerchantRules['multilingual_data.' + prefix + '_name'] = [{
          required: true,
          message: '请输入设施名称',
          trigger: 'blur'
        },]
        editMerchantRules['multilingual_data.' + prefix + '_subtitle'] = [{
          required: true,
          message: '请输入副标题',
          trigger: 'blur'
        },]
      }
      merchantRules.value = editMerchantRules
    }
    const deleteMerchant = () => {
      ElMessageBox.confirm(
        '确定要删除吗?',
        '删除设施',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'error',
        }
      ).then(() => {
        loading.value = true
        authApi.deleteMerchant(merchant.value.id).then(res => {
          console.log(res)
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
          store.commit('setMerchant', null)
          sessionStorage.removeItem('merchantId')
          router.replace({
            name: 'MerchantList',
          })
        }).finally(() => {
          loading.value = false
        })
      })

    }

    const getMerchantDetail = (item, force = false) => {
      return new Promise((resolve, reject) => {
        authApi.getMerchantDetail(item.id).then(res => {
          console.log(res.data)
          store.commit('setMerchant', res.data)
          resolve(merchant.value)
        }).catch(err => {
          reject(err)
        })
      })
    }

    const editMerchant = () => {
      merchantRuleFormRef.value.validate((valid) => {
        if (valid) {
          loading.value = true
          console.log(merchantForm.id)
          authApi.updateMerchant(merchantForm.id, merchantForm).then(res => {
            console.log(res)
            ElMessage({
              message: '编辑成功',
              type: 'success',
            })
            getMerchantDetail(merchantForm, true).then(res => {

            })
          }).finally(() => {
            loading.value = false
          })
        }
      })
    }

    const loading = ref(false)

    const userAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.user_avatar = null
      }
      console.log(file)
      const userReader = new FileReader();

      userReader.onload = () => {
        merchantForm.user_avatar = userReader.result;
        merchantForm.user_avatar_url = userReader.result;
      };
      userReader.readAsDataURL(file.raw);
    }

    const assistantAvatarHandleChange = (file, fileList) => {
      if (file === '') {
        merchantForm.assistant_avatar = null
      }
      console.log(file)
      const assistantReader = new FileReader();
      assistantReader.readAsDataURL(file.raw);
      assistantReader.onload = () => {
        merchantForm.assistant_avatar = assistantReader.result;
        merchantForm.assistant_avatar_url = assistantReader.result;
      };
      assistantReader.readAsDataURL(file.raw);
    }


    onMounted(() => {
      mappingMerchant()
    })
    return {
      config,
      loading,
      merchant,
      merchantServerLanguages,
      merchantForm,
      merchantRuleFormRef,
      merchantRules,
      merchantWelcomeContent,
      mappingMerchant,
      editMerchant,
      deleteMerchant,
      userAvatarHandleChange,
      assistantAvatarHandleChange,
    }
  }
}
</script>
<style scoped>
.preview-cell {
  white-space: pre-line;
}

.document-tabs .el-tabs__item {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
